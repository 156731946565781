<template>
    <div class="page talron main-page scan-page">
        <layout-welcome
            :topBlockColorDesktop="''"
            :topBlockColorMobile="''"
            :topBlockBackgroundUrlMobile="getDynamicAssetUrl('images/backgrounds/scan-bg-desktop.webp')"
            :topBlockBackgroundUrlDekstop="getDynamicAssetUrl('images/backgrounds/scan-bg-desktop.webp')"
            :bottomBlockColorDesktop="'var(--global-background)'"
            :bottomBlockColorMobile="''"
            :bottomBlockBackgroundUrlDesktop="''"
            :bottomBlockBackgroundUrlMobile="''"
        >

            <template #topBlock>

                <div class="user-greet" v-if="hasUser">
                    <div class="profile-info uk-flex uk-flex-row uk-flex-center uk-flex-middle uk-gap-s">
                        <img class="profile-icon"
                             :src="getDynamicAssetUrl('images/other/user-profile-icon.svg')"
                             alt=""/>
                        <span class="profile-text">{{ userAccountName }}</span>
                        <span class="profile-text">{{ userName }}</span>
                    </div>
                   <h1 aria-live="assertive" tabindex="0"
                        class="welcome-title uk-text-center">{{ timeOfDayGreet }}</h1>
                </div>
                <div class="guest-greet" v-else>
                        <h1 aria-live="assertive" tabindex="0"
                        class="welcome-title uk-text-center">
                        {{ translate('talron.scan.guestGreetLine1') }}<br>{{ translate('talron.scan.guestGreetLine2') }}
                    </h1>
                </div>

                <div class="desktop-code-explain uk-visible@l">
                    <span>{{ translate('talron.scan.desktopExplain') }}</span>
                </div>

                <base-form
                    class="intro-form"
                    method="get"
                    :action="'battery/'+batteryFormData.batteryBarcode"
                    v-model="batteryFormData"
                    @form:submitSuccess="handleGetBatteryInfoSuccess"
                    :config="scanFormConfig"></base-form>

                <a class="no-barcode-link uk-margin-top uk-margin-small-bottom "
                   v-if="hasUser"
                   href="#"
                   role="button"
                   @click.prevent="revealBatterySearchForm">{{ translate('talron.scan.noBarcodeSearchCts') }}</a>

                <a class="no-barcode-link uk-margin-top "
                   v-else
                   href="#"
                   @click.prevent="$router.push({name:'login'})">
                    <span uk-icon="talron-wrench"></span>
                    <span>{{ translate('talron.scan.serviceProviderAccessCta') }}</span>
                </a>

            </template>
            <template #bottomBlock>
                <div class="info-block-inner">
                    <title-separator >
                        <h2 class="uk-margin-remove tr-font-m tr-font-bolder">{{translate('talron.scan.explainTitle')}}</h2>
                    </title-separator>
                    <ul class="steps">
                        <li class="step">
                            <div class="step-number">1</div>
                            <div class="step-content">{{ translate('talron.scan.explainStep1') }}</div>
                        </li>
                        <li class="step">
                            <div class="step-number">2</div>
                            <div class="step-content">{{ translate('talron.scan.explainStep2') }}</div>
                        </li>
                        <li class="step">
                            <div class="step-number">3</div>
                            <div class="step-content">{{ translate('talron.scan.explainStep3') }}</div>
                        </li>
                    </ul>
                </div>
            </template>
        </layout-welcome>

        <teleport to="body">
            <div class="form-search-overlay uk-animation-fade uk-animation-fast"
                 role="dialog"
                 aria-labelledby="no-barcode-dialog-title"
                 aria-describedby="dialog1-desc"
                 v-show="showBatterySearchForm ">
              <!--showBatterySearchForm-->
                <div class="form-search-content uk-animation-slide-bottom uk-animation-fast">
                    <span uk-icon="talron-close"
                          role="button"
                          tabindex="0"
                          :aria-label="translate('talron.scan.noBarcodeSearchClose')"
                          class="form-search-close"
                          @click="showBatterySearchForm = false"></span>
                    <div class="form-search-inner">
                        <div class="form-header uk-text-center uk-margin-auto" style="max-width: 400px;">
                            <h3 class="uk-margin-remove uk-text-bold tr-font-l"
                                ref="batterySearchDialogTitle"
                                tabindex="-1"
                                id="no-barcode-dialog-title">
                                {{ translate('talron.batterySearch.formTitle') }}</h3>
                            <p class="search-form-explain uk-margin-remove" id="dialog1-desc">
                                {{ translate('talron.batterySearch.formExplain') }}</p>
                        </div>
                        <separator class="uk-margin-small uk-margin-bottom"
                                   style="width: 80%; max-width: 210px"></separator>
                        <base-form

                            class="intro-form uk-margin-auto"
                            method="get"
                            :action="'battery/no-serial'"
                            v-model="searchFormData"
                            @form:submitSuccess="handleGetBatteryInfoSuccessNoSerial"
                            :config="searchFormConfig"></base-form>
                    </div>
                </div>
            </div>
        </teleport>
    </div>
</template>

<script>

import {useStore, mapGetters} from "vuex";
import asyncOperations from '@/client/extensions/composition/asyncOperations.js';
import {computed} from 'vue';
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";

export default {
    setup(props) {
        let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());
        let {meta}                                 = useMeta({title: useI18n().t('talron.scan.pageTitle')}).meta

        return {asyncOps, asyncOpsReady, asyncStatus, meta};
    },
    data() {
        let currentYear = new Date().getFullYear();
        return {
            batteryFormData      : {
                batteryBarcode: config.talron.useDummyBattery ? 'SU1220040675' : '',
                userId        : computed(() => this.$store.getters['talron/userId']),
            },
            searchFormData       : {
                userId    : computed(() => this.$store.getters['talron/userId']),
                brand     : null,
                size      : null,
                type      : null,
                startMonth: null,
                endMonth  : null,
                year      : null,

            },
            batteryOptions       : {
                types      : [
                    {
                        value: 1,
                        label: 'SLI',
                    },
                    {
                        value: 2,
                        label: 'EFB',
                    },
                    {
                        value: 3,
                        label: 'AGM',
                    },
                    {
                        value: 4,
                        label: 'אופטימה',
                    }
                ],
                brands     : [
                    {
                        value: '1',
                        label: 'VARTA',
                    },
                    {
                        value: '2',
                        label: 'START-UP 12',
                    },
                    {
                        value: '3',
                        label: 'START-UP 18',
                    },
                    /*
                    {
                        value: '4',
                        label: 'MEIR',
                    },
                    {
                        value: '5',
                        label: 'MEIR 12',
                    },
                    {
                        value: '6',
                        label: 'CHECKUP',
                    },
                    */
                    {
                        value: '7',
                        label: 'COLMOBIL',
                    },
                    {
                        value: '8',
                        label: 'SPARK',
                    },
                    {
                        value: '9',
                        label: 'BLACK BOX',
                    },
                    {
                        value: '10',
                        label: 'OPTIMA',
                    },
                    {
                        value: '11',
                        label: 'FIAMM',
                    },
                    {
                        value: '12',
                        label: 'PLATIN',
                    },
                    {
                        value: '13',
                        label: 'ENERGIZER',
                    },
                    {
                        value: '14',
                        label: 'SAMELET',
                    },
                    {
                        value: '15',
                        label: 'MCT-12',
                    },
                    {
                        value: '16',
                        label: 'MCT-24',
                    },

                ],
                sizes      : [
                    {value: '35', label: '35'},
                    {value: '40', label: '40'},
                    {value: '44', label: '44'},
                    {value: '45', label: '45'},
                    {value: '50', label: '50'},
                    {value: '52', label: '52'},
                    {value: '55', label: '55'},
                    {value: '60', label: '60'},
                    {value: '70', label: '70'},
                    {value: '71', label: '71'},
                    {value: '72', label: '72'},
                    {value: '74', label: '74'},
                    {value: '75', label: '75'},
                    {value: '80', label: '80'},
                    {value: '85', label: '85'},
                    {value: '88', label: '88'},
                    {value: '90', label: '90'},
                    {value: '95', label: '95'},
                    {value: '100', label: '100'},
                    {value: '105', label: '105'},
                    {value: '110', label: '110'},
                    {value: '120', label: '120'},
                    {value: '125', label: '125'},
                    {value: '140', label: '140'},
                    {value: '165', label: '165'},
                    {value: '170', label: '170'},
                    {value: '180', label: '180'},
                    {value: '190', label: '190'},
                    {value: '210', label: '210'},
                    {value: '220', label: '220'},
                    {value: '225', label: '225'},
                    {value: '240', label: '240'},
                ],
                startMonths: [
                    {value: 1, label: '01'},
                    {value: 2, label: '02'},
                    {value: 3, label: '03'},
                    {value: 4, label: '04'},
                    {value: 5, label: '05'},
                    {value: 6, label: '06'},
                    {value: 7, label: '07'},
                    {value: 8, label: '08'},
                    {value: 9, label: '09'},
                    {value: 10, label: '10'},
                    {value: 11, label: '11'},
                    {value: 12, label: '12'},
                ],
                endMonths  : [
                    {value: 1, label: '01'},
                    {value: 2, label: '02'},
                    {value: 3, label: '03'},
                    {value: 4, label: '04'},
                    {value: 5, label: '05'},
                    {value: 6, label: '06'},
                    {value: 7, label: '07'},
                    {value: 8, label: '08'},
                    {value: 9, label: '09'},
                    {value: 10, label: '10'},
                    {value: 11, label: '11'},
                    {value: 12, label: '12'},
                ],
                years      : [
                    {value: currentYear, label: String(currentYear)},
                    {value: currentYear - 1, label: String(currentYear - 1)},
                    {value: currentYear - 2, label: String(currentYear - 2)},
                    {value: currentYear - 3, label: String(currentYear - 3)},
                    {value: currentYear - 4, label: String(currentYear - 4)},
                    {value: currentYear - 5, label: String(currentYear - 5)},
                    {value: currentYear - 6, label: String(currentYear - 6)},
                    {value: currentYear - 7, label: String(currentYear - 7)},
                    {value: currentYear - 8, label: String(currentYear - 8)},
                    {value: currentYear - 9, label: String(currentYear - 9)},
                    {value: currentYear - 10, label: String(currentYear - 10)},
                ]
            },
            searchFormConfig     : {
                fields: {
                    brand: {
                        name         : 'brand',
                        type         : 'select',
                        list         : computed(() => this.batteryOptions.brands),
                        label        : 'talron.batterySearch.brandLabel',
                        nullOption   : {value: null, label: this.translate('talron.batterySearch.brandPlaceholder')},
                        placeholder  : this.translate('talron.batterySearch.brandPlaceholder'),
                        translateList: false,
                        validation   : {
                            required: {},
                        },
                        group        : 'group1',
                    },
                    type : {
                        name         : 'type',
                        type         : 'select',
                        list         : computed(() => this.searchFormComputedTypes),
                        label        : 'talron.batterySearch.typeLabel',
                        nullOption   : {value: null, label: this.translate('talron.batterySearch.typePlaceholder')},
                        placeholder  : this.translate('talron.batterySearch.typePlaceholder'),
                        translateList: false,
                        validation   : {
                            required: {},
                        },
                        group        : 'group1',
                    },
                    size : {
                        name         : 'size',
                        type         : 'select',
                        list         : computed(() => this.searchFormComputedSizes),
                        nullOption   : {value: null, label: this.translate('talron.batterySearch.sizePlaceholder')},
                        label        : 'talron.batterySearch.sizeLabel',
                        placeholder  : this.translate('talron.batterySearch.sizePlaceholder'),
                        translateList: false,
                        validation   : {
                            required: {},
                        },
                        group        : 'group1',
                    },
                    /*
                     heatStampLabel: {
                     name: 'heatStampLabel',
                     type: 'freeLabel',
                     label: false,
                     placeholder: 'talron.batterySearch.dateFieldsetLabel',
                     group: 'group2'
                     },*/
                    startMonth: {
                        name         : 'startMonth',
                        type         : 'select',
                        list         : computed(() => this.batteryOptions.startMonths),
                        nullOption   : {
                            value: null,
                            label: this.translate('talron.batterySearch.startMonthPlaceholder')
                        },
                        label        : false,
                        ariaLabel : this.translate('talron.batterySearch.startMonthAriaLabel'),
                        placeholder  : this.translate('talron.batterySearch.startMonthPlaceholder'),
                        translateList: false,
                        validation   : {
                            required: {},
                        },
                        group        : 'group2',
                    },
                    endMonth  : {
                        name         : 'endMonth',
                        type         : 'select',
                        list         : computed(() => this.batteryOptions.endMonths),
                        nullOption   : {value: null, label: this.translate('talron.batterySearch.endMonthPlaceholder')},
                        label        : false,
                        ariaLabel : this.translate('talron.batterySearch.endMonthAriaLabel'),
                        placeholder  : this.translate('talron.batterySearch.endMonthPlaceholder'),
                        translateList: false,
                        validation   : {
                            required: {},
                        },
                        group        : 'group2',
                    },
                    year      : {
                        name         : 'year',
                        type         : 'select',
                        list         : computed(() => this.batteryOptions.years),
                        nullOption   : {value: null, label: this.translate('talron.batterySearch.yearPlaceholder')},
                        label        : false,
                        'aria-label' : this.translate('talron.batterySearch.yearPlaceholder'),
                        placeholder  : this.translate('talron.batterySearch.yearPlaceholder'),
                        translateList: false,
                        validation   : {
                            required: {},
                        },
                        group        : 'group3',

                    },
                    userId    : {
                        type: 'hidden',
                        name: 'userId'
                    }
                },
                groups: {
                    group1: {
                        component: 'block',
                        props    : {
                            class: 'group1'
                        }
                    },
                    group2: {
                        component: 'talronFlexFieldset',
                        props    : {
                            label: 'talron.batterySearch.dateFieldsetLabel',
                            class: 'group2'
                        }
                    },
                    group3: {
                        component: 'block',
                        props    : {
                            class: 'group3'
                        }
                    },
                },
                buttons: {
                    submit: {
                        type: 'alt',
                        text: 'talron.batterySearch.buttonCta',

                    }
                }
            },
            scanFormConfig       : {
                fields : {
                    batteryBarcode: {
                        name       : 'batteryBarcode',
                        label      : false,
                        placeholder: 'talron.scan.scanFieldPlaceholder',
                        type       : 'TalronBatteryBarcodeScandit', //'TalronBatteryBarcode'
                        // iconEnd     : "barcode",
                        wrapperStyle: 'max-width: 100%;',
                        validation  : {
                            required      : {},
                            batteryBarcode: {},
                        },
                    },
                    userId        : {
                        name : 'userId',
                        label: false,
                        type : 'hidden',
                    }
                },
                buttons: {
                    'submit': {
                        'type': 'alt',
                        'text': 'talron.login.buttonCta'
                    }
                }
            },
            showBatterySearchForm: false,
            brandBatteryOptionsMap : {
                '1': {
                    label: 'VARTA',
                    types: [
                        {
                            value: 1,
                            label: 'SLI',
                        },
                        {
                            value: 2,
                            label: 'EFB',
                        },
                        {
                            value: 3,
                            label: 'AGM',
                        },
                    ],
                    sizes: {
                        1: [{"value": "40", "label": "40"}, {"value": "44", "label": "44"}, {
                            "value": "45",
                            "label": "45"
                        }, {"value": "50", "label": "50"}, {"value": "52", "label": "52"}, {
                            "value": "55",
                            "label": "55"
                        }, {"value": "60", "label": "60"}, {"value": "62", "label": "62"}, {
                            "value": "63",
                            "label": "63"
                        }, {"value": "65", "label": "65"}, {"value": "70", "label": "70"}, {
                            "value": "71",
                            "label": "71"
                        }, {"value": "74", "label": "74"}, {"value": "80", "label": "80"}, {
                            "value": "88",
                            "label": "88"
                        }, {"value": "90", "label": "90"}, {"value": "100", "label": "100"}, {
                            "value": "110",
                            "label": "110"
                        }, {"value": "140", "label": "140"}, {"value": "170", "label": "170"}, {
                            "value": "180",
                            "label": "180"
                        }, {"value": "225", "label": "225"}],
                        2: [{"value": "65", "label": "65"}, {"value": "60", "label": "60"}, {
                            "value": "70",
                            "label": "70"
                        }, {"value": "72", "label": "72"}, {"value": "75", "label": "75"}, {
                            "value": "80",
                            "label": "80"
                        }, {"value": "85", "label": "85"}, {"value": "190", "label": "190"}, {
                            "value": "240",
                            "label": "240"
                        }],
                        3: [{"value": "60", "label": "60"}, {"value": "70", "label": "70"}, {
                            "value": "80",
                            "label": "80"
                        }, {"value": "95", "label": "95"}, {"value": "105", "label": "105"}, {
                            "value": "210",
                            "label": "210"
                        }],

                    }
                },
                '2': {
                    label: 'START-UP 12',
                    types: [
                        {
                            value: 1,
                            label: 'SLI',
                        },
                    ],
                    sizes: {
                        1: [{"value": "35", "label": "35"}, {"value": "44", "label": "44"}, {
                            "value": "45",
                            "label": "45"
                        }, {"value": "52", "label": "52"}, {"value": "55", "label": "55"}, {
                            "value": "60",
                            "label": "60"
                        }, {"value": "63", "label": "63"}, {"value": "70", "label": "70"}, {
                            "value": "74",
                            "label": "74"
                        }, {"value": "80", "label": "80"}, {"value": "88", "label": "88"}, {
                            "value": "90",
                            "label": "90"
                        }, {"value": "100", "label": "100"}, {"value": "105", "label": "105"}, {
                            "value": "110",
                            "label": "110"
                        }, {"value": "120", "label": "120"}, {"value": "125", "label": "125"}, {
                            "value": "140",
                            "label": "140"
                        }, {"value": "170", "label": "170"}, {"value": "180", "label": "180"}, {
                            "value": "225",
                            "label": "225"
                        }],
                    }
                },
                '3': {
                    label: 'START-UP 18',
                    types: [
                        {
                            value: 1,
                            label: 'SLI',
                        },
                    ],
                    sizes: {
                        1: [{"value": "35", "label": "35"}, {"value": "44", "label": "44"}, {
                            "value": "45",
                            "label": "45"
                        }, {"value": "52", "label": "52"}, {"value": "55", "label": "55"}, {
                            "value": "60",
                            "label": "60"
                        }, {"value": "63", "label": "63"}, {"value": "70", "label": "70"}, {
                            "value": "74",
                            "label": "74"
                        }, {"value": "80", "label": "80"}, {"value": "88", "label": "88"}, {
                            "value": "90",
                            "label": "90"
                        }, {"value": "100", "label": "100"}, {"value": "105", "label": "105"}, {
                            "value": "110",
                            "label": "110"
                        }, {"value": "120", "label": "120"}, {"value": "125", "label": "125"}, {
                            "value": "140",
                            "label": "140"
                        }, {"value": "170", "label": "170"}, {"value": "180", "label": "180"}, {
                            "value": "225",
                            "label": "225"
                        }],
                    }
                },
                // 4 - MEIR - unavailable in origin list
                // 5-  MEIR 12 - unavailable in origin list
                '6': {
                    label: 'CHECKUP',
                    types: [
                        {
                            value: 1,
                            label: 'SLI',
                        },
                    ],
                    sizes: {
                        1: [{"value": "35", "label": "35"}, {"value": "44", "label": "44"}, {
                            "value": "45",
                            "label": "45"
                        }, {"value": "52", "label": "52"}, {"value": "55", "label": "55"}, {
                            "value": "60",
                            "label": "60"
                        }, {"value": "63", "label": "63"}, {"value": "70", "label": "70"}, {
                            "value": "74",
                            "label": "74"
                        }, {"value": "80", "label": "80"}, {"value": "88", "label": "88"}, {
                            "value": "90",
                            "label": "90"
                        }, {"value": "100", "label": "100"}, {"value": "105", "label": "105"}, {
                            "value": "110",
                            "label": "110"
                        }, {"value": "120", "label": "120"}, {"value": "125", "label": "125"}, {
                            "value": "140",
                            "label": "140"
                        }, {"value": "170", "label": "170"}, {"value": "180", "label": "180"}, {
                            "value": "225",
                            "label": "225"
                        }],
                    }
                },
                '7': {
                    label: 'COLMOBIL',
                    types: [
                        {
                            value: 1,
                            label: 'SLI',
                        },
                    ],
                    sizes: {
                        1: [{"value": "35", "label": "35"}, {"value": "44", "label": "44"}, {
                            "value": "45",
                            "label": "45"
                        }, {"value": "52", "label": "52"}, {"value": "55", "label": "55"}, {
                            "value": "60",
                            "label": "60"
                        }, {"value": "63", "label": "63"}, {"value": "70", "label": "70"}, {
                            "value": "74",
                            "label": "74"
                        }, {"value": "80", "label": "80"}, {"value": "88", "label": "88"}, {
                            "value": "90",
                            "label": "90"
                        }, {"value": "100", "label": "100"}, {"value": "105", "label": "105"}, {
                            "value": "110",
                            "label": "110"
                        }, {"value": "120", "label": "120"}, {"value": "125", "label": "125"}, {
                            "value": "140",
                            "label": "140"
                        }, {"value": "170", "label": "170"}, {"value": "180", "label": "180"}, {
                            "value": "225",
                            "label": "225"
                        }],
                    }
                },
                '8': {
                    label: 'SPARK',
                    types: [
                        {
                            value: 1,
                            label: 'SLI',
                        },
                    ],
                    sizes: {
                        1: [{"value": "35", "label": "35"}, {"value": "44", "label": "44"}, {
                            "value": "45",
                            "label": "45"
                        }, {"value": "52", "label": "52"}, {"value": "55", "label": "55"}, {
                            "value": "60",
                            "label": "60"
                        }, {"value": "63", "label": "63"}, {"value": "70", "label": "70"}, {
                            "value": "74",
                            "label": "74"
                        }, {"value": "80", "label": "80"}, {"value": "88", "label": "88"}, {
                            "value": "90",
                            "label": "90"
                        }, {"value": "100", "label": "100"}, {"value": "105", "label": "105"}, {
                            "value": "110",
                            "label": "110"
                        }, {"value": "120", "label": "120"}, {"value": "125", "label": "125"}, {
                            "value": "140",
                            "label": "140"
                        }, {"value": "170", "label": "170"}, {"value": "180", "label": "180"}, {
                            "value": "225",
                            "label": "225"
                        }],
                    }
                },
                '9': {
                    label: 'BLACKBOX',
                    types: [
                        {
                            value: 1,
                            label: 'SLI',
                        },
                    ],
                    sizes: {
                        1: [{"value": "35", "label": "35"}, {"value": "44", "label": "44"}, {"value": "45", "label": "45"}, {"value": "52", "label": "52"}, {"value": "55", "label": "55"}, {"value": "60", "label": "60"}, {"value": "63", "label": "63"}, {"value": "70", "label": "70"}, {"value": "74", "label": "74"}, {"value": "80", "label": "80"}, {"value": "88", "label": "88"}, {"value": "90", "label": "90"}, {"value": "100", "label": "100"}, {"value": "105", "label": "105"}, {"value": "110", "label": "110"}, {"value": "120", "label": "120"}, {"value": "125", "label": "125"}, {"value": "140", "label": "140"}, {"value": "170", "label": "170"}, {"value": "180", "label": "180"}, {"value": "225", "label": "225"}],
                    }
                },
                '10': {
                    label: 'OPTIMA',
                    types: [
                        {
                            value: 4,
                            label: 'אופטימה',
                        }
                    ],
                    sizes: {
                        4: [{"value": "50", "label": "50"}, {"value": "55", "label": "55"}, {"value": "66", "label": "66"}, {"value": "75", "label": "75"}],
                    },
                },
                '11': {
                    label: 'FIAMN',
                    types: [
                        {
                            value: 1,
                            label: 'SLI',
                        },
                    ],
                    sizes: {
                        1: [{"value":"60","label":"60"},{"value":"70","label":"70"},{"value":"88","label":"88"}],
                    }
                },
                '12': {
                    label: 'PLATIN',
                    types: [
                        {
                            value: 1,
                            label: 'SLI',
                        },
                        {
                            value: 2,
                            label: 'EFB',
                        },

                    ],
                    sizes: {
                        1: [{"value":"35","label":"35"},{"value":"45","label":"45"},{"value":"50","label":"50"},{"value":"55","label":"55"},{"value":"60","label":"60"},{"value":"66","label":"66"},{"value":"70","label":"70"},{"value":"100","label":"100"},{"value":"105","label":"105"},{"value":"120","label":"120"},{"value":"165","label":"165"},{"value":"180","label":"180"},{"value":"220","label":"220"}],
                        2: [{"value":"60","label":"60"},{"value":"70","label":"70"}],

                    }
                },
                '13': {
                    label: 'ENERGIZER',
                    types: [
                        {
                            value: 1,
                            label: 'SLI',
                        },
                    ],
                    sizes: {
                     1:[{"value":"40","label":"40"},{"value":"44","label":"44"},{"value":"45","label":"45"},{"value":"50","label":"50"},{"value":"52","label":"52"},{"value":"55","label":"55"},{"value":"60","label":"60"},{"value":"62","label":"62"},{"value":"63","label":"63"},{"value":"65","label":"65"},{"value":"70","label":"70"},{"value":"71","label":"71"},{"value":"74","label":"74"},{"value":"80","label":"80"},{"value":"88","label":"88"},{"value":"90","label":"90"},{"value":"100","label":"100"}],
                    }
                },
                '14': {
                    label: 'SAMELET',
                    types: [
                        {
                            value: 1,
                            label: 'SLI',
                        },
                    ],
                    sizes: {
                        1: [{"value":"35","label":"35"},{"value":"44","label":"44"},{"value":"45","label":"45"},{"value":"52","label":"52"},{"value":"55","label":"55"},{"value":"60","label":"60"},{"value":"63","label":"63"},{"value":"70","label":"70"},{"value":"74","label":"74"},{"value":"80","label":"80"},{"value":"88","label":"88"},{"value":"90","label":"90"},{"value":"100","label":"100"},{"value":"105","label":"105"},{"value":"110","label":"110"},{"value":"120","label":"120"},{"value":"125","label":"125"},{"value":"140","label":"140"},{"value":"170","label":"170"},{"value":"180","label":"180"},{"value":"225","label":"225"}],
                    }
                },
                '15': {
                    label: 'MCT-12',
                    types: [
                        {
                            value: 1,
                            label: 'SLI',
                        },
                    ],
                    sizes: {
                        1: [{"value":"35","label":"35"},{"value":"44","label":"44"},{"value":"45","label":"45"},{"value":"52","label":"52"},{"value":"55","label":"55"},{"value":"60","label":"60"},{"value":"63","label":"63"},{"value":"70","label":"70"},{"value":"74","label":"74"},{"value":"80","label":"80"},{"value":"88","label":"88"},{"value":"90","label":"90"},{"value":"100","label":"100"},{"value":"105","label":"105"},{"value":"110","label":"110"},{"value":"120","label":"120"},{"value":"125","label":"125"},{"value":"140","label":"140"},{"value":"170","label":"170"},{"value":"180","label":"180"},{"value":"225","label":"225"}],
                    }
                },
                '16': {
                    label: 'MCT-24',
                    types: [
                        {
                            value: 1,
                            label: 'SLI',
                        },
                    ],
                    sizes: {
                        1: [{"value":"35","label":"35"},{"value":"44","label":"44"},{"value":"45","label":"45"},{"value":"52","label":"52"},{"value":"55","label":"55"},{"value":"60","label":"60"},{"value":"63","label":"63"},{"value":"70","label":"70"},{"value":"74","label":"74"},{"value":"80","label":"80"},{"value":"88","label":"88"},{"value":"90","label":"90"},{"value":"100","label":"100"},{"value":"105","label":"105"},{"value":"110","label":"110"},{"value":"120","label":"120"},{"value":"125","label":"125"},{"value":"140","label":"140"},{"value":"170","label":"170"},{"value":"180","label":"180"},{"value":"225","label":"225"}],
                    }
                },
            },
            asyncData            : {
                /*
                 summaryData: {
                 target: 'project/summary',
                 data: {
                 'from': computed(() => {
                 let d = new Date();
                 d.setFullYear( d.getFullYear() - 1);
                 d.setDate(1);
                 return d.toISOString().split('T')[0]
                 })
                 }
                 },
                 statuses: 'project/status'
                 */
            },
        }
    },
    components: {},
    computed  : {
        ...mapGetters({
                          'user'           : 'talron/user',
                          'hasUser'        : 'talron/hasUser',
                          'userName'       : 'talron/userName',
                          'userAccountName': 'talron/userAccountName',
                          'userId'         : 'talron/userId',
                          'hasBattery'     : 'talron/hasBattery',
                          'batteryInfo'    : 'talron/battery'
                      }),
        userPersonalId: {
            get() {
                return this.$store.getters['talron/userPersonalId'];
            },
            set(val) {
                return true;
            }
        },
        mainBlockBackgroundImageUrlCss() {
            let src = this.getDynamicAssetUrl('images/backgrounds/scan-bg-desktop.webp');
            return `url('${src}')`;
        },
        timeOfDayGreet() {
            let hour = (new Date()).getHours();
            if (hour >= 3 && hour <= 11) {
                return this.translate('talron.welcome.goodMorning');
            }

            if (hour >= 12 && hour <= 15) {
                return this.translate('talron.welcome.goodNoon');
            }

            if (hour >= 15 && hour <= 19) {
                return this.translate('talron.welcome.goodAfterNoon');
            }

            return this.translate('talron.welcome.goodEvening');
        },
        searchFormComputedTypes () {
            if ( ! this.searchFormData.brand) {
                return this.batteryOptions.types;
            }

            if ( ! this.brandBatteryOptionsMap.hasOwnProperty(this.searchFormData.brand)) {
                return this.batteryOptions.types;
            }

            return this.brandBatteryOptionsMap[this.searchFormData.brand].types;

        },
        searchFormComputedSizes () {
            // no brand? return all
            if ( ! this.searchFormData.brand) {
                console.log('no brand');
                return this.batteryOptions.sizes;
            }

            // unknown brand? return all
            if ( ! this.brandBatteryOptionsMap.hasOwnProperty(this.searchFormData.brand)) {
                console.log('no brand found');
                return this.batteryOptions.sizes;
            }

            // no type? return all
            if ( ! this.searchFormData.type) {
                console.log('no type');
                return this.batteryOptions.sizes;
            }

            let conf = this.brandBatteryOptionsMap[this.searchFormData.brand];

            // unknown type? return all
            if ( ! conf.sizes.hasOwnProperty(this.searchFormData.type)) {
                return this.batteryOptions.sizes;
            }

            return conf.sizes[this.searchFormData.type];
        }
    },
    methods   : {
        async handleGetBatteryInfoSuccessNoSerial(eventData) {
            let data = eventData.result.data;

            if (data.status != '1') {
                let errorText =  typeof data.rejectionReason === 'string' ? data.rejectionReason : this.translate('talron.scan.scanGeneralError');
                return this.$s.ui.notification(errorText, 'error');
            }

            this.$store.commit('talron/setBattery', {...data});

            this.$router.push({name: 'battery'});
        },
        async handleGetBatteryInfoSuccess(eventData) {
            let data = eventData.result.data;

            if (data.status != '1') {
                let errorText =  typeof data.rejectionReason === 'string' ? data.rejectionReason : this.translate('talron.scan.scanGeneralError');
                return this.$s.ui.notification(errorText, 'error');
            }

            this.$store.commit('talron/setBattery', {...data});

            this.$router.push({name: 'battery'});
        },
        async revealBatterySearchForm() {
            this.showBatterySearchForm = true;
            await utilities.wait(150);
            this.$refs.batterySearchDialogTitle.focus();

        }
    },
    mounted() {

    },
    watch: {
      'searchFormData': {
        handler: function (newVal) {
          console.log('changed', newVal);
        },
        deep: true,
        immediate: true,
      },
        'searchFormData.startMonth': function (newVal, oldVal) {
            let target                   = newVal === 12 ? 1 : +newVal + 1;
            this.searchFormData.endMonth = Number(target);

        }
    },

}
</script>

<style lang="scss" scoped>

.desktop-code-explain {
    font-size     : var(--font-m);
    font-weight   : 700;
    margin-bottom : 13px;
}

.profile-info, .welcome-title {
    color : var(--global-primary-background-dark);
}

.profile-info {
    @media (min-width : 1200px) {
        display         : flex;
        flex-direction  : column;
        align-items     : center;
        justify-content : center;
        font-size       : 24px;

        .profile-icon {
            height        : 45px;
            width         : 40px;
            margin-bottom : 3px;
        }

        .profile-text {
            margin-top : -12px;
        }
    }
}

.welcome-title {
    font-size     : var(--font-xl);
    font-weight   : bold;
    line-height   : 1;
    margin-top    : 2px;
    margin-bottom : 18px;

    @media (min-width : 1200px) {
        margin-bottom : 20px;
    }

}

.battery-code-input-wrapper {
    width         : 100%;
    max-width     : 500px;
    margin-bottom : 10px;

    @media (min-width : 1200px) {
        max-width     : 500px;
        width         : 500px;
        margin-bottom : 20px;

    }

}

.scan-button-group {
    margin-bottom : 10px;

    @media (min-width : 1200px) {
        margin-bottom : 0;
    }
}

.no-barcode-link {
    color           : var(--global-primary-background-darkest);
    font-weight     : bolder;
    text-decoration : underline;

    &:hover {
        color : var(--global-link-color);
    }
}

.steps {
    display : block;
    padding : 10px 30px 0 30px;
    list-style: none;

    .step {
        display         : flex;
        align-items     : center;
        justify-content : flex-start;
        width           : 100%;
        gap             : 15px;
        margin-bottom   : 25px;

        &:last-of-type {
            margin-bottom : 0;
        }

        .step-number {
            display         : flex;
            height          : 24px;
            width           : 24px;
            flex            : 0 0 24px;
            border-radius   : 50%;
            border          : 2px solid var(--global-inverse-color);
            align-items     : center;
            justify-content : center;
            text-align      : center;
            line-height     : 1;
            font-weight     : bold;
            font-size       : var(--font-m);
        }

        .step-content {
            font-weight : 500;
            display     : inline-block;
        }
    }

    // this includes override for the nested step. it is batter to manage it like this
    @media (min-width : 1200px) {
        display         : flex;
        justify-content : space-between;
        margin-top      : 45px;
        margin-bottom   : 10px;

        .step {
            display         : flex;
            flex-direction  : column;
            justify-content : flex-start;
            align-items     : center;
            gap             : 10px;
            width           : 150px;

            .step-number {
                display          : flex;
                align-items      : center;
                justify-content  : center;
                height           : 30px;
                width            : 30px;
                flex             : 0 0 30px;
                font-size        : 18px;
                font-weight      : bold;
                text-align       : center;
                color            : var(--global-inverse-color);
                border-radius    : 50%;
                background-color : var(--global-primary-background);
            }

            .step-content {
                font-weight : 600;
                text-align  : center;
                color       : var(--global-primary-background-dark);
            }
        }
    }
}

.form-search-overlay {
    height          : 100vh;
    width           : 100vw;
    position        : fixed;
    left            : 0;
    top             : 0;
    z-index         : 99;
    background      : rgba(0, 0, 0, 0.7);
    display         : flex;
    justify-content : center;
    align-items     : center;
    max-width       : 100%;


    .form-search-close {
        position         : absolute;
        top              : 15px;
        inset-inline-end : 15px;
        cursor           : pointer;

        @media (min-width : 1200px) {
            top              : 25px;
            inset-inline-end : 25px;
        }
    }

    .form-header {
        max-width : 80%;
    }

    .form-search-content {
        background    : var(--global-background);
        border-radius : 25px 25px 0 0;
        width         : 100%;

        align-self    : flex-end;
        overflow-y    : scroll;
        overflow-x    : hidden;
        max-height    : calc(100% - 40px);


        @media (min-width : 1200px) {
            align-self    : center;
            border-radius : 25px;
            max-width     : 400px;
            margin-top    : auto;
            margin-bottom : auto;
        }

        .form-search-inner {
            padding : calc(var(--global-margin) * 1.75);
           // accounts for stupid iphone bottom overlay
            padding-bottom: 140px;

        }

        .search-form-explain {
            margin-top    : 2px;
            margin-bottom : 2px;
            font-size     : 15px;

            @media (min-width : 768px) {
                font-size : 16px;
            }
        }
    }
}
</style>

<style lang="scss">
// NOT SCOPED
.battery-code-input-wrapper, .form--intro-form {
    .uk-input {
        border-color : var(--global-color);
        font-weight  : bold;

        &::-webkit-input-placeholder,
        &::placeholder {
            border-color : var(--global-color);
            font-weight  : bold;
            color: var(--global-color);
            opacity: 0.8;
        }
    }

    .uk-form-controls .uk-form-icon {
        color             : var(--global-color);
        margin-inline-end : 10px;

        @media (min-width : 1200px) {
            display : none;
        }
    }
}

// we override like this to allow meeting design requirements with a single slot output
.scan-page .info-block .title-separator {
    .content-text {
        background : transparent !important;
    }

    @media (max-width : 1200px) {
        .content {
            color : var(--global-background) !important;

            .content-text h2 {
                color : var(--global-background) !important;
            }
        }

        .line {
            background-color : var(--global-background) !important;
        }
    }

    @media (min-width : 1200px) {
        .content {
            color : var(--global-color) !important;
        }

        .line {
            background-color : var(--global-color) !important;
        }
    }


}

</style>




